import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReportService {
  private runUUID = new BehaviorSubject<string | null>(null);
  runUUID$ = this.runUUID.asObservable();
  private dastUUID = new BehaviorSubject<string | null>(null);
  dastUUID$ = this.dastUUID.asObservable();

  setRun(runUUID: string) {
    this.runUUID.next(runUUID);
  }

  clearRun() {
    this.runUUID.next(null);
  }

  setDast(dastUUID: string) {
    this.dastUUID.next(dastUUID);
  }

  clearDast() {
    this.dastUUID.next(null);
  }
}
